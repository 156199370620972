<template>
  <div class="modal-body gallery-modal">
    <button type="button" class="gallery-modal__close" @click="$emit('close')">
      <IconComponent name="close" />
    </button>
    <template v-if="images.length > 1">
      <button type="button" class="gallery-modal__arrow gallery-modal__arrow--left" @click="prev">
        <IconComponent name="arrow-left-fill" />
      </button>
      <button type="button" class="gallery-modal__arrow gallery-modal__arrow--right" @click="next">
        <IconComponent name="arrow-right-fill" />
      </button>
    </template>
    <a
      :href="$store.state._env.MEDIA_ENDPOINT + images[i].img.path"
      target="_blank"
      type="button"
      class="gallery-modal__external"
    >
      <IconComponent name="external" />
    </a>
    <img
      :src="$store.state._env.MEDIA_ENDPOINT + images[i].img.path"
      :alt="images[i].img.alt"
      class="gallery-modal__image"
    />
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "GalleryModal",
  props: {
    index: Number,
    images: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    i: 0,
  }),
  mounted() {
    this.i = this.index || 0;
  },
  methods: {
    prev() {
      if (this.i - 1 < 0) {
        this.i = this.images.length - 1;
      } else {
        this.i -= 1;
      }
    },
    next() {
      if (this.i + 1 > this.images.length - 1) {
        this.i = 0;
      } else {
        this.i += 1;
      }
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.gallery-modal {
  padding 0
  position relative
  max-width calc(100% - 150px)
  width auto
  min-width 200px
  min-height 250px
  +below(1024px) {
    margin-top: 60px;
  }
  +below(788px) {
    max-width calc(100% - 150px)
  }

  &__image {
    width 100%
    height 100%
    background var(--gray)
    border-radius 10px
    border none
    outline none
    box-shadow none
    object-fit contain
  }

  &__close {
    width 50px
    height 50px
    border-radius 100%
    background var(--white)
    absolute right -50px top -50px
    border: 1px solid var(--dark-o1);
    display inline-flex
    align-items center
    justify-content center
    cursor pointer

    .icon {
      width 22px
      height 22px

      svg path {
        fill var(--dark)
      }
    }
  }

  &__external {
    width 50px
    height 50px
    border-radius 100%
    background var(--white)
    absolute right 5px top 5px
    border: 1px solid var(--dark-o1);
    display inline-flex
    align-items center
    justify-content center
    opacity 0.3
    transition opacity var(--transition)

    &:hover {
      opacity 1
    }

    &:active {
      transform scale(0.96)
    }

    .icon {
      width 22px
      height 22px

      svg path {
        fill var(--dark)
      }
    }
  }

  &__arrow {
    absolute top bottom
    height 50px
    width 50px
    background var(--white)
    border-radius 100%
    border: 1px solid var(--dark-o1);
    display inline-flex
    align-items center
    justify-content center
    margin auto
    cursor pointer

    .icon {
      width 22px
      height 22px

      svg path {
        fill var(--dark)
      }
    }

    &--right {
      right -55px
    }

    &--left {
      left -55px
    }

    &:active {
      transform scale(0.96)
    }
  }
}
</style>
