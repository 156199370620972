<template>
  <main class="culture-page page">
    <BreadcrumbsComponent
      :title="culture.title"
      :links="[{ title: 'Культура против террора', to: { name: 'cultures' } }]"
    />
    <div class="container-padding">
      <div v-if="culture" class="culture-page__inner">
        <div class="culture-page__body">
          <h2 v-if="culture.title" class="culture-page__title">{{ culture.title }}</h2>
          <span v-if="culture.description">{{ culture.description }}</span>
        </div>
        <ul v-if="culture.images" class="culture-page__images">
          <li v-for="(image, i) in culture.images" :key="i">
            <a :href="image.img.path" @click.prevent="showGallery(culture.images, i)">
              <ImgComponent :img="image.img" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
import GalleryModal from "components/modals/components/GalleryModal.vue";
import CULTURES_OPEN_PAGE from "gql/pages/CulturesOpenPage.graphql";
export default {
  name: "GalleriesOpenPage",
  metaInfo() {
    const meta = this.$store.state.cultures.cultures_item;
    return {
      title: meta?.title,
    };
  },
  async asyncData({ res, apollo, store, route }) {
    if (!route.params.id && res) {
      res.status(404);
    }
    await apollo.defaultClient
      .query({
        query: CULTURES_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        if (data) {
          store.dispatch("cultures/save", data);
        } else {
          if (res) {
            res.status(404);
          }
        }
      })
      .catch(() => {});
  },
  computed: {
    culture() {
      return this.$store.state.cultures.cultures_item;
    },
  },
  methods: {
    showGallery(images, index = 0) {
      this.$store.state._modals = [
        {
          component: GalleryModal,
          options: {
            images,
            index,
          },
        },
      ];
    },
  },
  components: {
    ImgComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.culture-page {

  &__inner {
    padding 50px 0 0 0
    display grid
    grid-gap 30px
  }

  &__body {
    display grid
    grid-gap 15px
  }

  &__title {
    margin-bottom 0
  }

  &__images {
    display flex
    flex-wrap wrap
    gap 15px

    li {
      display inline-flex
      width 100%
      max-width 330px
      +below(700px) {
        max-width 400px
      }

      a {
        display inline-flex
        width 100%

        img {
          height 320px
          max-height 320px
          border-radius 10px
        }
      }
    }
  }
}
</style>
